<template>
  <div class="">
    <el-row :gutter="10" style="min-height: 60px;" v-loading="designConfig.isLoadData">
      <template v-if="designConfig.data && designConfig.data.length > 0">
        <template v-for="design in designConfig.data">
        <el-col :span="8">
          <a :href="`/design/view/${design.designid}`" target="_blank">
            <div class="sctp-bg-white sctp-flex sctp-flex-column sctp-mar-t10">
              <el-image
                style="width: 100%;height: 200px;"
                :src="design.designcoverImgurl" fit="cover"
              ></el-image>
              <div class="" style="padding: 0 8px 8px;">
                <div class="sctp-ellipsis sctp-mar-tb5">{{ design.designname }}</div>
                <div class="flex flex-sb fz-12 fc-info">
                  <div>
                    <span>{{ design.subTypeName }}</span>
                  </div>
                  <div>
                    <span>{{ getCity(design.cityCode) }}</span>
                  </div>
                </div>
                <div class="divider"></div>
                <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                  <div class="sctp-red">{{ design.saleprice ? `¥${design.saleprice}` : '免费' }}</div>
                  <div class="">浏览数：{{ design.hits || 0 }}</div>
                </div>
                <div @click.prevent="" class="flex flex-sb pad-lr5">
                  <div></div>
                  <div>
                    <el-dropdown @command="handleCommand($event, design)" size="mini">
                      <i class="el-icon-more"></i>
                      <el-dropdown-menu slot="dropdown">
                        <template v-for="item in dropdownOptions(design)">
                          <el-dropdown-item :command="item">{{ item.label }}</el-dropdown-item>
                        </template>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </el-col>
      </template>
      </template>
      <template v-else>
        <div class="empty-text">暂无数据</div>
      </template>
    </el-row>
    <div class="sctp-pad-tb10 sctp-flex sctp-flex-fe sctp-bg-white sctp-mar-t10">
      <el-pagination
        :current-page="designConfig.pageConfig.page"
        :page-size="designConfig.pageConfig.limit"
        @size-change="designConfig.pageConfig.handleSizeChange"
        @current-change="designConfig.pageConfig.handleCurrentChange"
        :page-sizes="designConfig.pageConfig.pageSizes"
        layout="prev, pager, next, total, sizes"
        :total="designConfig.pageConfig.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {collection} from "@/apis/index";
import area from "@/resources/js/area";

export default {
  name: 'favorite-design',
  data() {
    return {
      designConfig: {
        isLoadData: false,
        data: [],
        loadData: () => {
          let {pageConfig: {page, limit}} = this.designConfig;
          this.designConfig.isLoadData = true;
          collection.getDesign({
            userId: this.user.userId,
            page, limit,
          }).then(res => {
            let {data, count = 0} = res;
            this.designConfig.data = data
            this.designConfig.pageConfig.total = count;
          }).finally(() => {
            this.designConfig.isLoadData = false;
          });
        },
        pageConfig: {
          page: 1,
          limit: 12,
          total: 0,
          pageSizes: [1, 2, 5, 10].map(item => item * 12),
          handleSizeChange: (limit) => {
            this.designConfig.pageConfig.page = 1;
            this.designConfig.pageConfig.limit = limit;
            this.designConfig.loadData();
          },
          handleCurrentChange: (page) => {
            this.designConfig.pageConfig.page = page;
            this.designConfig.loadData();
          }
        },
      }
    }
  },
  methods: {
    handleCommand: (command, item) => {
      command.command(item);
    },
  },
  computed: {
    dropdownOptions() {
      return function (item) {
        let options = [];
        options = [
          {
            label: '取消收藏', command: () => {
              collection.addFavorite({
                userId: (this.user && this.user.userId) || null,
                targetId: item.designid,
                targetType: 4
              }).then(res => {
                const {data} = res;
                if (data) {
                  this.$message.success('收藏成功')
                } else {
                  this.$message.success('取消收藏成功')
                }
                this.designConfig.loadData();
              });
            }
          },
        ];
        return options;
      }
    },
    getCity() {
      return function (code) {
        return area.parseCity(code).name;
      }
    },
  },
  beforeMount() {
    this.designConfig.loadData();
  }
}
</script>

<style scoped>
</style>
